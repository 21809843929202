<template>
  <div class="video-ideaWrapper">
    <section>
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        src="https://www.youtube-nocookie.com/embed/MsuxqZtOXXM?si=yClpJEOL5p0EBsZy"
        title="YouTube video player">
      </iframe>
    </section>
  </div>
</template>

<style lang="scss">
.video-ideaWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 99;
  color: #fff;

  section {
    iframe {
      height: calc(100vh - 4px);
      width: calc(100vw - 4px);
      box-sizing: border-box;
    }
  }
}
</style>

<script>
export default {
  name: "Video"
}
</script>
